// Smooth scroll on click the link with hash
const anchors = document.querySelectorAll('[href^="#"]');
anchors.forEach(anchor => {
    if (anchor.classList.contains('js-smooth-scroll')) {
        anchor.addEventListener('click', event => {
            event.preventDefault();
            const blockPos = anchor.dataset.scrollBlock;
            const targetElId = anchor.hash.slice(1);
            const targetEl = document.getElementById(targetElId);

            if (targetEl) {
                targetEl.scrollIntoView({
                    block: blockPos || 'center',
                    behavior: 'smooth',
                });
            }
        });
    }
});
